import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const NotFoundPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>404 - Not found</h1>
            <p>What you where looking for could not be found.</p>
            <p>Would you like to <a href="/">go home</a> instead?</p>
            {FooterBar()}
        </div>
    )
}

export default NotFoundPage
